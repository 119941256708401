/* eslint-disable */
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Icon from '@material-ui/core/Icon';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import { SocialIcon } from 'react-social-icons';
// core components
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-kit-pro-react/components/headerLinksStyle.js';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === '/sections') {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();

  // const currentClass = classes.listItem
  return (
    <List className={classes.list + ' ' + classes.mlAuto}>
      <ListItem
        className={classes.listItem}
        style={{ marginLeft: 10, marginRight: 10 }}
      >
        <AnchorLink style={{ color: 'inherit' }} offset="100" href="#omoss">
          Om oss
        </AnchorLink>
      </ListItem>
      <ListItem
        className={classes.listItem}
        style={{ marginLeft: 10, marginRight: 10 }}
      >
        <AnchorLink style={{ color: 'inherit' }} href="#meny">
          Meny
        </AnchorLink>
      </ListItem>
      <ListItem
        className={classes.listItem}
        style={{ marginLeft: 10, marginRight: 10 }}
      >
        <AnchorLink style={{ color: 'inherit' }} offset="100" href="#takeaway">
          Bestilling
        </AnchorLink>
      </ListItem>
      <ListItem
        className={classes.listItem}
        style={{ marginLeft: 10, marginRight: 60 }}
      >
        <AnchorLink style={{ color: 'inherit' }} href="#retningslinjer">
          Salgsvilkår
        </AnchorLink>
      </ListItem>
      {/* <ListItem
        className={classes.listItem}
        style={{ marginLeft: 30, marginRight: 30 }}
      >
        <AnchorLink style={{ color: 'inherit' }} href="#kontakt">
          Kontakt
        </AnchorLink>
      </ListItem> */}
      <ListItem
        className={classes.listItem}
        style={{ marginLeft: 5, marginRight: 5 }}
      >
        <SocialIcon url="https://www.facebook.com/MatpaaFarten" />
      </ListItem>
      <ListItem className={classes.listItem} style={{ marginLeft: 5 }}>
        <SocialIcon url="https://www.instagram.com/matpaafarten/" />
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: 'primary',
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    'dark',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
  ]),
};
