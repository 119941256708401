import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

import Loader from 'react-loader-spinner';

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';

import workStyle from 'assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.js';

const useStyles = makeStyles(workStyle);

export default function SectionKontakt() {
  var testing = 0;

  var UPLOAD_URL = testing ? 'http://localhost:5000/mail' : '/mail';

  const [info, setInfo] = React.useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setloading] = React.useState(false);
  const [enableButton, setenableButton] = React.useState(true);

  React.useEffect(() => {
    VerifyButton();
    return () => {
      setenableButton(true);
    };
  }, [info]);

  const classes = useStyles();

  const sendMessage = async () => {
    setloading(true);
    try {
      const res = await axios.post(UPLOAD_URL, info).then((res) => {
        console.log(res.data);
        setInfo({ name: '', email: '', message: '' });
        setloading(false);
      });
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        console.log('There was a problem with the server');
      } else {
        console.log(err);
      }
    }
  };

  const LoadingSpinner = () => {
    return (
      <div className="overlay">
        <Loader
          className="loader"
          type="ThreeDots"
          color="rgba(0,0,240,.6)"
          height="100"
          width="100"
        />
      </div>
    );
  };

  const VerifyButton = () => {
    if (!info.message == '' && !info.name == '' && !info.email == '') {
      setenableButton(false);
    } else {
      setenableButton(true);
    }
  };

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={8} md={8}>
          <h2 className={classes.title}>Kontakt oss</h2>
          <h4 className={classes.description}>
            Har du noen spørsmål om vår virksomhet, eller du har lyst til å
            jobbe sammen med oss?{<br />} Ta kontakt :)
          </h4>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Navn"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setInfo({ ...info, name: e.target.value });
                    },
                    value: info.name,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setInfo({ ...info, email: e.target.value });
                    },
                    value: info.email,
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Melding"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  onChange: (e) => {
                    setInfo({ ...info, message: e.target.value });
                  },
                  value: info.message,
                }}
              />
              <GridItem
                xs={12}
                sm={4}
                md={4}
                className={classes.mrAuto + ' ' + classes.mlAuto}
              >
                <Button
                  style={{ backgroundColor: '#cc6633' }}
                  // disabled={enableButton}
                  onClick={() => sendMessage()}
                >
                  Send Melding
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {loading ? <LoadingSpinner /> : null}
    </div>
  );
}
