/*eslint-disable*/ import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite';
// core components
import Header from 'components/Header/Header.js';
import Footer from './Sections/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';

import landingPageStyle from 'assets/jss/material-kit-pro-react/views/landingPageStyle.js';

// Sections for this page
import SectionOmoss from './Sections/SectionOmoss.js';
import SectionTeam from './Sections/SectionTeam.js';
import SectionKontakt from './Sections/SectionKontakt.js';
import SectionRetningslinjer from './Sections/SectionRetningslinjer.js';
import SectionTakeAway from './Sections/SectionTakeAway.js';
import SectionMenu from './Sections/SectionMenu.js';
const useStyles = makeStyles(landingPageStyle);

export default function LandingPage({ ...rest }) {
  const classes = useStyles();
  return (
    <div id="home">
      <Header
        color="transparent"
        brand="Mat På Farten"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: 'rgba(255,153,51,.5)',
        }}
        {...rest}
      />
      <Parallax
        image={require('assets/samlet.jpg')}
        filter="dark"
        style={{ width: '100vw' }}
      >
        <div className={classes.container}>
          <img className="logo" src={require('assets/logo.png')} />
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div id="omoss">
            <SectionOmoss />
          </div>
          <div id="meny">
            <SectionMenu />
          </div>
          <div id="takeaway">
            <SectionTakeAway />
          </div>
          <div id="retningslinjer">
            <SectionRetningslinjer />
          </div>
          {/* <div id="kontakt">
            <SectionKontakt />
          </div> */}
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              Mat på farten {<br />}Org nr: 925943312 - Telefon : 92989920
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} utviklet av{' '}
              <a href="https://facebook.com/flexboks" target="_blank">
                Flexboks AS
              </a>
            </div>
          </div>
        }
      />
    </div>
  );
}
