import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import productStyle from 'assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js';

const useStyles = makeStyles(productStyle);

export default function SectionMenu() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <h1>Meny</h1>
          <img
            src={require('assets/meny.png')}
            style={{ borderRadius: 5, maxWidth: '100%' }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
