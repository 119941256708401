import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import officeStyle from 'assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js';
import Button from '@material-ui/core/Button';

// office
import office1 from 'assets/buffalo.jpg';
import office2 from 'assets/kylling.jpg';
import office3 from 'assets/biffwrap.jpg';
import office4 from 'assets/samlet2conv.jpg';
import office5 from 'assets/samlet.jpg';

const useStyles = makeStyles(officeStyle);

export default function SectionTakeAway() {
  const classes = useStyles();
  return (
    <div className={classes.office}>
      <GridContainer className={classes.textCenter}>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}>
          <h1 className={classes.title}>Take Away</h1>
          <Button
            variant='contained'
            color='primary'
            size='large'
            style={{ margin: 40 }}
            onClick={() =>
              window.open(
                'https://menus.preoday.com/Mat-pa-Farten-Asane#/main/venue/menu'
              )
            }>
            Bestill mat Åsane!
          </Button>
          <Button
            variant='contained'
            color='primary'
            size='large'
            style={{ margin: 40 }}
            onClick={() =>
              window.open(
                'https://menus.preoday.com/Mat-pa-Farten#/main/venue/menu'
              )
            }>
            Bestill mat Søreide!
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office1}
            alt='office1'
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office2}
            alt='office2'
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office3}
            alt='office3'
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office4}
            alt='office4'
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office5}
            alt='office5'
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
