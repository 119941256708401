import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

import productStyle from 'assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js';

const useStyles = makeStyles(productStyle);

export default function SectionRetningslinjer() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2
            className={classes.title}
            style={{
              marginBottom: '60px',
              marginTop: '60px',
              textAlign: 'center',
            }}
          >
            Salgsvilkår
          </h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem style={{ color: 'black' }}>
            <ul style={{ listStyle: 'none', margin: 'auto', padding: 0 }}>
              <h4 style={{ color: 'black' }}> Mat på Farten </h4>
              <li>
                {<br />}Org nr: 925943312 {<br />} Adresse: Dolvikbakken 4, 5252
                Søreidgrend. tlf nr: 92989920 {<br />} Mail Adresse:
                post@matpaafarten. {<br />} {<br />}
                Salg av ferske baguetter, wraps og Buffalowings til både frokost
                og lunsj.
              </li>
              <li>
                Betaling - du kan betale med vipps (i appen) stripe/kort eller
                kontant hos oss i vognen.
              </li>
              <li>
                Levering - Maten kan bestilles dagen før eller dirkete når mann
                ønsker.
              </li>
              <li>Angrerett - Det er ikke angrerett på salg av ferskvarer.</li>
              <li>
                Mangel ved varen - kjøperens rettigheter og reklamasjonsfrist.
                {<br />}
                Reklamasjon kan meldes på post@matpaafarten.no
              </li>
              <li>
                Konfliktløsning - Klager rettes til selger innen rimelig tid,
                jf.
              </li>
              <li>
                Partene skal forsøke å løse eventuelle tvister i rimelighet.
                {<br />}
                Dersom dette ikke lykkes, kan kjøperen ta kontakt med
                Forbrukerrådet for mekling.
                <br /> Forbrukerrådet er tilgjengelig på telefon 23 400 500
                eller www.forbrukerradet.no.
              </li>
            </ul>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
