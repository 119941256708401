import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import FlightTakeoff from '@material-ui/icons/FlightTakeoff';
import Explore from '@material-ui/icons/Explore';
import Work from '@material-ui/icons/Work';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

import productStyle from 'assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js';

const useStyles = makeStyles(productStyle);

export default function SectionOmoss() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2
            className={classes.title}
            style={{ marginBottom: 40, marginTop: 40 }}
          >
            Om Mat på farten :)
          </h2>
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <h5 className={classes.description} style={{ marginBottom: 20 }}>
            Mat på farten er et sjarmerende lite spisested , med gode
            smaksopplevelser.
            <br /> Vi serverer nysmurte saftige baguetter , Wraps og
            buffalowings.
            <br /> Matvognen står sentralt plassert ved Rema 1000 søreide.
            <br /> Kom innom oss for en gourmet baguette 😉 Vi leverer også til
            bedrifter , ta kontakt for mer info!
          </h5>
        </GridItem>
        <GridItem xs={12}>
          <img
            src={require('assets/vogn2.jpg')}
            style={{ borderRadius: 5, maxWidth: '100%' }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
